#city {
  max-width: 750px;
  padding-top: 20px;
  margin: 0 auto;
  margin-top: 20px;
  position: relative;
}
#city li {
  padding-top: 10px;
}
#city .province {
  display: block;
}
#city .iconfont {
  position: absolute;
  right: 0;
  top: 0;
}
